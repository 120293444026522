import { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import css from 'styled-jsx/css'

import { logger } from '@ui/analytics'
import Footer from '@ui/components/shared/Footer'
import Header from '@ui/components/shared/Header'
import { useNotiContext } from '@ui/components/shared/Notification'
import Loading from '@ui/components/shared/Loading'
import { isPayingWithPaypalKey, useGlobalState } from '@libs/client'
import getLogRocket from '@ui/analytics/logRocket'
import Collections from '@ui/components/collections/Collections'
import BestSellers from '@ui/components/collections/BestSellers'
import Banner from '@ui/components/banner/Banner'

const IndexPage: NextPage = () => {
  const [isPayingWithPaypal] = useGlobalState<boolean>(isPayingWithPaypalKey)

  const router = useRouter()
  const error = router.query.error?.toString()
  const { notiDispatch } = useNotiContext()

  useEffect(() => {
    getLogRocket().logRocket?.init()
  }, [])

  useEffect(() => {
    logger.logProductEvent('view_product')
    if (error === 'offer-expired') {
      notiDispatch({
        payload: {
          content: 'Your order is expired!',
          type: 'is-warning',
          removable: false,
          timeout: 1000
        },
        type: 'REMOVE_ALL_AND_ADD'
      })
      router.replace('/')
    }
  }, [])

  return (
    <div className="product-page">
      <style jsx global>
        {globalStyle}
      </style>
      {isPayingWithPaypal ? (
        <Loading isFullWidth />
      ) : (
        <>
          <Header haveSaleBar />
          <Banner />
          <Collections />
          <BestSellers />
          <Footer />
        </>
      )}
    </div>
  )
}

const globalStyle = css.global`
  .product-page {
    background-color: var(--white);
    .top-description {
      margin-bottom: 61px;
    }
    .product {
      padding-top: 30px;
      padding-bottom: 105px;
      @media screen and (max-width: 768px) {
        padding-top: 0;
        padding-bottom: 48px;
      }
      &__rated {
        display: flex;
        align-items: center;
        font-size: 12px;

        .stars {
          margin-bottom: 4px;
          margin-left: 12px;
        }
      }
    }

    @media screen and (max-width: 768px) {
      .product-images {
        margin-bottom: 24px;
      }
    }

    .loading-component {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1;
      height: 100vh;
      width: 100vw;
    }
  }
`

export default IndexPage
