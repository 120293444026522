import React from 'react'
import { useRedirect, useSettingBvf } from '@ui/hooks'
import { originalImageBvf, thumbImageBvf } from '@libs/client'

export default function Banner() {
  const settingBvf = useSettingBvf()
  const redirect = useRedirect()

  return (
    <div className="container-fluid">
      <div className="banner-container container-beva">
        <div className="banner-container__content">
          <div className="infomation-text padding-x-12">
            <h1 className="title-banner">{settingBvf?.title_banner}</h1>
            <p
              className="subtitle-1"
              dangerouslySetInnerHTML={{
                __html: settingBvf?.description_banner || ''
              }}
            ></p>
            <button
              onClick={() => redirect('/c/' + (settingBvf?.bestseller_path || ''))}
              className="button-banner"
            >
              {settingBvf?.text_button}
            </button>
          </div>
        </div>

        <img
          src={thumbImageBvf(settingBvf?.banner || '')}
          data-src={originalImageBvf(settingBvf?.banner || '')}
          className="banner-desktop lazyload"
          alt="banner"
        ></img>
        <img
          src={thumbImageBvf(settingBvf?.banner_mobile || '')}
          data-src={originalImageBvf(settingBvf?.banner_mobile || '')}
          className="banner-mobile lazyload"
          alt="banner"
        ></img>
      </div>
      <style jsx global>{`
        .container-fluid {
          background-color: var(--primary-10);
          margin-bottom: 36px;
        }
        .banner-container {
          padding: 40px 0px 0px;
          text-align: center;
          min-height: 420px;

          color: var(--gray-8);

          &__content {
            margin-bottom: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .infomation-text {
            max-width: 587px;
            padding: 0px 12px;
          }

          .title-banner {
            font-weight: 500;
            font-size: 48px;
            line-height: 52px;

            margin-bottom: 24px;
          }

          p {
            margin-bottom: 24px;
          }

          .button-banner {
            border: 1px solid var(--gray-8);
            border-radius: 4px;
            padding: 10px 58px;
            cursor: pointer;

            color: var(--gray-9);
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            text-align: center;
            letter-spacing: 0.02em;

            background-color: transparent;
          }
          .banner-desktop {
            display: none;
          }
          img {
            width: 100%;
            max-width: 375px;
            display: block;
            margin: auto;
          }
        }

        @media screen and (min-width: 992px) {
          .container-fluid {
            margin-bottom: 52px;
          }
          .banner-container {
            display: flex;
            justify-content: center;
            text-align: left;
            padding: 0px 12px;

            &__content {
              max-width: 50%;
              margin-bottom: 0px;
            }

            .infomation-text {
              padding: 0px;
            }

            .title-banner {
              font-weight: 500;
              font-size: 48px;
              line-height: 62px;
              letter-spacing: -0.04em;
            }

            img {
              width: 50%;
              max-width: 560px;
            }

            .banner-mobile {
              display: none;
            }
            .banner-desktop {
              display: block;
            }
          }
        }
      `}</style>
    </div>
  )
}
