import React, { FC, useEffect, useState } from 'react'
import css from 'styled-jsx/css'

type StarRatingProps = {
  editable?: boolean
  rating?: number
  onChange?: (idx: number) => void
  className?: string
  colorActive?: string
  colorInActive?: string
}

const StarRating: FC<StarRatingProps> = ({ editable = false, rating = 0, onChange, className }) => {
  const [currentRating, setCurrentRating] = useState(rating)

  useEffect(() => setCurrentRating(rating), [rating])

  const onMouseEnter = (idx: number) => () => editable && setCurrentRating(idx)

  const onMouseLeave = () => editable && setCurrentRating(rating)

  const onClick = (idx: number) => () => onChange?.(idx)

  return (
    <div className={`star-rating is-inline-flex ${className || ''}`}>
      <style jsx global>
        {globalStyle}
      </style>
      {[1, 2, 3, 4, 5].map((idx) => {
        return (
          <React.Fragment key={`rating_${idx}`}>
            {Math.floor(currentRating) >= idx ? (
              <svg
                className={`star ${editable ? 'editable' : ''}`}
                onMouseEnter={onMouseEnter(idx)}
                onMouseLeave={onMouseLeave}
                onClick={onClick(idx)}
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M23.8213 10.8947L17.5259 9.93661L14.7117 3.96218C14.6348 3.7986 14.5083 3.66618 14.3521 3.58569C13.9604 3.38317 13.4843 3.55194 13.2884 3.96218L10.4742 9.93661L4.17884 10.8947C4.00528 10.9207 3.84659 11.0063 3.7251 11.1362C3.57822 11.2943 3.49728 11.5069 3.50007 11.7275C3.50286 11.948 3.58915 12.1584 3.73997 12.3124L8.29477 16.9626L7.21868 23.529C7.19345 23.6818 7.20959 23.8389 7.26528 23.9825C7.32096 24.1261 7.41397 24.2505 7.53374 24.3416C7.65352 24.4327 7.79528 24.4869 7.94294 24.4979C8.09059 24.5089 8.23825 24.4764 8.36916 24.404L14.0001 21.3039L19.6309 24.404C19.7847 24.4897 19.9632 24.5183 20.1343 24.4871C20.5657 24.4092 20.8558 23.9808 20.7814 23.529L19.7053 16.9626L24.2601 12.3124C24.3841 12.1851 24.4659 12.019 24.4907 11.8372C24.5577 11.3828 24.2552 10.9622 23.8213 10.8947V10.8947Z"
                  fill="#FDD835"
                />
              </svg>
            ) : (
              <>
                {Math.floor(currentRating) + 1 === idx &&
                Math.floor(currentRating) !== currentRating ? (
                  <svg
                    className="star star-half"
                    onMouseEnter={onMouseEnter(idx)}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick(idx)}
                    width="28"
                    height="28"
                    viewBox="0 0 28 28"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_18835_5841"
                      maskUnits="userSpaceOnUse"
                      x="3"
                      y="3"
                      width="22"
                      height="22"
                    >
                      <path
                        d="M23.8213 10.8947L17.5259 9.93661L14.7117 3.96218C14.6348 3.7986 14.5083 3.66618 14.3521 3.58569C13.9604 3.38317 13.4843 3.55194 13.2884 3.96218L10.4742 9.93661L4.17884 10.8947C4.00528 10.9207 3.84659 11.0063 3.7251 11.1362C3.57822 11.2943 3.49728 11.5069 3.50007 11.7275C3.50286 11.948 3.58915 12.1584 3.73997 12.3124L8.29477 16.9626L7.21868 23.529C7.19345 23.6818 7.20959 23.8389 7.26528 23.9825C7.32096 24.1261 7.41397 24.2505 7.53374 24.3416C7.65352 24.4327 7.79528 24.4869 7.94294 24.4979C8.09059 24.5089 8.23825 24.4764 8.36916 24.404L14.0001 21.3039L19.6309 24.404C19.7847 24.4897 19.9632 24.5183 20.1343 24.4871C20.5657 24.4092 20.8558 23.9808 20.7814 23.529L19.7053 16.9626L24.2601 12.3124C24.3841 12.1851 24.4659 12.019 24.4907 11.8372C24.5577 11.3828 24.2552 10.9622 23.8213 10.8947Z"
                        fill="#E0E3E9"
                      />
                    </mask>
                    <g mask="url(#mask0_18835_5841)">
                      <rect x="2" y="2" width="12" height="24" fill="#FDD835" />
                      <rect x="14" y="2" width="11" height="24" fill="#E0E3E9" />
                    </g>
                  </svg>
                ) : (
                  <>
                    <svg
                      className={`star ${editable ? 'editable' : ''}`}
                      onMouseEnter={onMouseEnter(idx)}
                      onMouseLeave={onMouseLeave}
                      onClick={onClick(idx)}
                      width="28"
                      height="28"
                      viewBox="0 0 28 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M23.8213 10.8947L17.5259 9.93661L14.7117 3.96218C14.6348 3.7986 14.5083 3.66618 14.3521 3.58569C13.9604 3.38317 13.4843 3.55194 13.2884 3.96218L10.4742 9.93661L4.17884 10.8947C4.00528 10.9207 3.84659 11.0063 3.7251 11.1362C3.57822 11.2943 3.49728 11.5069 3.50007 11.7275C3.50286 11.948 3.58915 12.1584 3.73997 12.3124L8.29477 16.9626L7.21868 23.529C7.19345 23.6818 7.20959 23.8389 7.26528 23.9825C7.32096 24.1261 7.41397 24.2505 7.53374 24.3416C7.65352 24.4327 7.79528 24.4869 7.94294 24.4979C8.09059 24.5089 8.23825 24.4764 8.36916 24.404L14.0001 21.3039L19.6309 24.404C19.7847 24.4897 19.9632 24.5183 20.1343 24.4871C20.5657 24.4092 20.8558 23.9808 20.7814 23.529L19.7053 16.9626L24.2601 12.3124C24.3841 12.1851 24.4659 12.019 24.4907 11.8372C24.5577 11.3828 24.2552 10.9622 23.8213 10.8947V10.8947Z"
                        fill="#E0E3E9"
                      />
                    </svg>
                  </>
                )}
              </>
            )}
          </React.Fragment>
        )
      })}
    </div>
  )
}

const globalStyle = css.global`
  .star-rating {
    height: 28px;
    align-items: center;

    .star {
      height: 100%;
      width: auto;

      &.editable {
        cursor: pointer;
      }
    }

    .star-half {
      height: 100%;
      width: auto;
    }
  }
`

export default StarRating
