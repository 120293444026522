import { thumbImage } from '@libs/client'
import { imagePath, moneyConverter } from '@ui/helpers'
import StarRating from '../shared/StarRating'
import css from 'styled-jsx/css'

const sThumb = thumbImage('96x96')

export default function ProductItem({ item }: { item: S3Types.catalog_item }) {
  return (
    <div
      className={`product-card ${
        item?.is_coming_soon || item?.soldout ? 'active-hover-mobile' : ''
      } `}
    >
      <style jsx global>
        {globalStyle}
      </style>
      <div className="info">
        <figure className="thumbnail is-square">
          {/* <img
            src={sThumb(item.logo ?? '')}
            data-src={(() => {
              const image = item.logo ?? ''
              return imagePath(image)
            })()}
            alt=""
            className="lazyload"
          /> */}
          <picture>
            <source data-srcset={imagePath(item.logo ?? '')} />
            {item.fallback_logo ? (
              <source data-srcset={imagePath(item.fallback_logo ?? '')} />
            ) : (
              <></>
            )}
            <img
              src={sThumb(item.logo ?? '')}
              data-src={(() => {
                const image = item.logo ?? ''
                return imagePath(image)
              })()}
              alt="item thumb"
              className="lazyload"
            />
          </picture>
          {item.is_sale && <div className="flag-sale">{item.sale_label || 'SALE'}</div>}
          <div className="view-button">
            {item?.is_coming_soon || item?.soldout ? (
              <>
                <button className="button-product coming-soon-btn ">
                  {item?.is_coming_soon ? (
                    <>
                      <span className="is-hidden-mobile">Product is coming soon</span>
                      <span className="is-hidden-tablet"> Coming soon</span>
                    </>
                  ) : (
                    <>Sold out</>
                  )}
                </button>
              </>
            ) : (
              <button className="button-product">View product</button>
            )}
          </div>
        </figure>
        <div className="sale-info">
          <div className="price">
            {item.is_sale ? (
              <>
                <div className="default header-6">${moneyConverter(item.price ?? 0)}</div>
              </>
            ) : (
              <div className="default-none header-6">${moneyConverter(item.price ?? 0)}</div>
            )}
            <div className="compared">${moneyConverter(item.compare_price ?? 0)}</div>
          </div>
          <h3 className="title">{item.title}</h3>
          <div className="rating">
            <StarRating rating={item?.rating_score || 5} />
            <div className="number-rating body-3">{`(${item?.total_rating || 229})`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .product-card {
    display: block;

    .info {
      padding: 24px 0px;

      border-bottom: 1px solid var(--indigo-4);

      display: flex;
      .thumbnail {
        margin-right: 12px;
        position: relative;
        display: flex;

        img {
          transition: opacity 0.3s ease-in-out;
          height: 170px;
          width: 170px;
          object-fit: cover;
          max-width: none;
        }
        .flag-sale {
          transition: opacity 0.3s ease-in-out;
          background-color: var(--red-400);
          border-radius: 0px;
          padding: 0px 12px;
          text-align: center;

          font-weight: 600;
          font-size: 12px;
          line-height: 24px;
          letter-spacing: 0.06em;
          color: var(--white-100);

          position: absolute;
          top: 0;
          left: 0;
        }
        .view-button {
          transition: opacity 0.3s ease-in-out;
          position: absolute;
          opacity: 0;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: none;
          align-items: center;

          .button-product {
            margin: 0px 24px;
            padding: 12px;
            width: 100%;
            border: none;
            border-radius: 4px;
            cursor: pointer;

            color: var(--gray-9);
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;

            text-align: center;

            background: var(--white);
          }
          .coming-soon-btn {
            cursor: auto;
            margin: 0px;
            border-radius: 0px;
            font-weight: 600;
            font-size: 16px;
            line-height: 28px;
            color: var(--gray-8);
          }
        }
      }

      .sale-info {
        flex-direction: column;

        .title {
          margin-bottom: 16px;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          max-height: 66px;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          color: var(--gray-8);
        }
        .price {
          display: flex;
          align-items: flex-end;
          margin-bottom: 8px;
          .default {
            color: var(--red-700);
          }

          .default-none {
            color: var(--gray-9);
          }

          .compared {
            text-decoration-line: line-through;
            font-weight: 400;
            font-size: 13px;
            line-height: 28px;
            color: var(--gray-6);
            margin-left: 8px;
          }
        }
        .rating {
          height: 24px;
          display: flex;
          align-items: center;
          .star-rating {
            height: 16px;
          }
          .number-rating {
            margin-left: 8px;
            color: var(--gray-9);
          }
        }
      }
    }
  }
  .active-hover-mobile {
    &:hover {
      .info {
        .thumbnail {
          img {
            filter: brightness(0.5);
          }
          .flag-sale {
            filter: brightness(0.5);
          }
          .view-button {
            display: flex;
            opacity: 1;
          }
        }
      }
    }
  }

  @media screen and (min-width: 768px) {
    .product-card {
      .info {
        padding: 0px 0px;
        border: none;
        display: block;

        .thumbnail {
          margin: 0px;
          .flag-sale {
            padding: 2px 14px;
            font-size: 14px;
            line-height: 24px;
          }
          img {
            height: 100%;
            width: 100%;
          }
        }

        .sale-info {
          margin-top: 16px;

          .title {
            padding-right: 12px;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;

            margin-bottom: 8px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            height: 48px;
          }
          .price {
            .default-none {
              color: var(--gray-8);
            }
            .compared {
              font-weight: 400;
              font-size: 15px;
              line-height: 28px;
              color: var(--gray-7);
            }
          }
        }
      }
      &:hover {
        .info {
          .thumbnail {
            img {
              filter: brightness(0.5);
            }
            .flag-sale {
              filter: brightness(0.5);
            }
            .view-button {
              display: flex;
              opacity: 1;
            }
          }
        }
      }
    }
  }
`
