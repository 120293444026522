import { useGlobalState, useTrackingService } from '@libs/client'
import { useEffect } from 'react'
import SfLink from '../next/SfLink'
import css from 'styled-jsx/css'
import ProductItem from '../product-item/ProductItem'

export default function BestSellers() {
  const [best_sellsers] = useGlobalState<S3Types.collection_data | null>('store.best_sellers')

  const { trackingViewItem } = useTrackingService()
  useEffect(() => {
    //TODO: tracking
    trackingViewItem()
  }, [])
  return (
    <div className="bestsellers-catalog container-beva">
      <style jsx global>
        {globalStyle}
      </style>
      <h1 className="header-5 hot-heading">{best_sellsers?.config?.hot?.heading || ''}</h1>

      <div className="columns">
        {best_sellsers?.catalog &&
          Object.entries(best_sellsers.catalog)
            ?.filter((catalog) =>
              (best_sellsers?.config?.hot?.show_hot_products || [])?.includes(
                catalog?.[1]?.id || ''
              )
            )
            .map(([subpath, item]) => (
              <div key={subpath} className="column is-12-mobile is-4-tablet is-3-desktop">
                {item?.is_coming_soon || item?.soldout ? (
                  <div className="not-href-link">
                    <ProductItem item={item} />
                  </div>
                ) : (
                  <SfLink pathName={`/${subpath}`}>
                    <ProductItem item={item} />
                  </SfLink>
                )}
              </div>
            ))}
      </div>
    </div>
  )
}

const globalStyle = css.global`
  .bestsellers-catalog {
    margin-bottom: 80px;
    display: block;
    h2 {
      color: var(--gray-8);
    }

    .columns {
      flex-wrap: wrap;
      grid-row-gap: 48px;
      margin-top: 0px;
      margin-bottom: 0px;

      .column {
        padding: 0 12px;

        &:last-child {
          .info {
            border-bottom: none;
          }
        }
      }
      .not-href-link {
        cursor: auto;
      }
    }
  }
  @media screen and (min-width: 768px) {
    .bestsellers-catalog {
      margin-bottom: 128px;

      .hot-heading {
        margin-bottom: 32px;
        text-align: center;
      }
    }
  }
  @media screen and (min-width: 1024px) {
    .bestsellers-catalog {
      .columns {
        grid-row-gap: 72px;
      }
    }
  }
`
