import { thumbImageBvf, useGlobalState } from '@libs/client'
// import { useEffect } from 'react'
import css from 'styled-jsx/css'
import SfLink from '../next/SfLink'
import { Swiper, SwiperSlide } from 'swiper/react'
import { SwiperOptions } from 'swiper'
import { useMounted, useSettingBvf } from '@ui/hooks'
import { iconPathBvf } from '@ui/helpers'

export default function Collections() {
  const [collections] = useGlobalState<S3Types.store_collections | null>('store.collections')
  const collectionsData = Object.entries(collections || {})?.filter(
    (item) => item?.[1]?.config?.visible_middle
  )
  const settingBvf = useSettingBvf()
  // const { trackingViewItem } = useTrackingService()
  const mouted = useMounted()
  const itemTotal = Object.entries(collectionsData || {}).length
  const swiperConfig: SwiperOptions = {
    slidesPerView: 2.5,
    spaceBetween: 12,
    pagination: {
      clickable: true
    },
    breakpoints: {
      450: {
        slidesPerView: itemTotal > 3.5 ? 3.5 : itemTotal,
        spaceBetween: 12
      },
      600: {
        slidesPerView: itemTotal > 3.5 ? 3.5 : itemTotal,
        spaceBetween: 20
      },
      768: {
        slidesPerView: itemTotal > 4.5 ? 4.5 : itemTotal,
        spaceBetween: 20
      },
      928: {
        slidesPerView: itemTotal,
        spaceBetween: 32,
        allowSlidePrev: false,
        allowSlideNext: false
      }
    }
  }

  // useEffect(() => {
  //   //TODO: tracking
  //   trackingViewItem()
  // }, [])
  return (
    <div className="collection-container">
      <style jsx global>
        {categoryStyle}
      </style>

      <h2 className="header-5 column">{settingBvf?.title_category}</h2>
      <div className="category" style={{ maxWidth: itemTotal > 5 ? 1120 : itemTotal * 192 }}>
        {mouted && (
          <Swiper {...swiperConfig}>
            {collectionsData &&
              collectionsData.map(([subpath, item]) => (
                <SwiperSlide key={subpath}>
                  <div className="colection-item">
                    <SfLink pathName={`/c/${subpath}`}>
                      <CollectionItem item={item as S3Types.collection_data} />
                    </SfLink>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
        )}
      </div>
    </div>
  )
}

const CollectionItem = ({ item }: { item: S3Types.collection_data }) => {
  return (
    <>
      <img
        src={thumbImageBvf(item.config?.image ?? '')}
        data-src={iconPathBvf(item.config?.image ?? '')}
        alt={item?.config?.label}
        className="hide-in-mobile lazyload"
      />
      <img
        src={thumbImageBvf(item.config?.image_mobile ?? '')}
        data-src={iconPathBvf(item.config?.image_mobile ?? '')}
        alt={item?.config?.label}
        className="hide-in-desktop lazyload"
      />

      <h3 className="label-category subtitle-2b text-center">{item.config?.label}</h3>
    </>
  )
}

const categoryStyle = css.global`
  .collection-container {
    .text-center {
      text-align: center;
    }
    .category {
      padding: 0px 0px 54px 12px;
      width: 100%;
      margin: auto;
    }
    h2 {
      color: var(--gray-8);
      padding-bottom: 24px;
    }

    .colection-item {
      background-color: var(--white-100);
      .label-category {
        margin: 16px 8px 0 8px;
        color: var(--gray-8);
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      &:hover {
        .label-category {
          color: var(--primary-100);
        }
      }

      img {
        width: 100%;
        display: block;
        max-height: 250px;
      }
    }

    @media screen and (min-width: 768px) {
      h2 {
        text-align: center;
      }
    }

    @media screen and (min-width: 1120px) {
      .category {
        padding-bottom: 88px;

        .colection-item {
          .label-category {
            margin: 16px 16px 0 16px;
          }
        }
      }
    }
  }
`
